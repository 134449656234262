import HostUrl from '@/utils/HostUrl.js'

let hostUrl = new HostUrl()

let host = hostUrl.getUrl(''),
  fms_host = hostUrl.getUrl('FMS'),
  region_host = hostUrl.getUrl('REGION'),
  goods_host = hostUrl.getUrl('GOODS'),
  scm_host = hostUrl.getUrl('SCM'),
  shop_host = hostUrl.getUrl('SHOP'),
  idaas_host = hostUrl.getUrl('IDAAS')

export default {
  host: host,
  authorizeOauth: host + '/oauth/authorize', //单点登录解析
  getSession: host + '/user/session', //user/session

  getRegionList: region_host + '/region/all', //获取地址

  switchParty: idaas_host + '/open/user/switchBu', //切换业务组
  merchantList: idaas_host + '/open/merchant/queryPage', //获取商户
  switchMerchant: idaas_host + '/open/switchMerchant', //切换商户
  switchApp: idaas_host + '/open/user/switchApp', //切换APP
  querySystemMessage: idaas_host + '/open/systemMessage/receive', //发布消息
  queryIdaasAppList: idaas_host + '/open/merchantApp/list', //idaas应用列表
  getDepartmentList: idaas_host + '/open/department/listDepartment', //部门列表
  getUserList: idaas_host + '/open/user/queryPage', //部门人员列表

  //goodsjieku
  getSkuList: goods_host + '/sku/query', //查询sku列表
  batchImportPurchaseOrderItems: scm_host + '/purchaseOrder/batchImportPurchaseOrderItems', //导入采购单商品信息
  //shop接口
  getWarehouseListNew: shop_host + '/warehouse/queryWarehousePage', //仓库列表
  getWarehouseShipperList: shop_host + '/shipper/page', //货主列表
  getNewShopInfoList: shop_host + '/shop/simplePage', //新店铺选择

  filePreUpload: host + '/ossapi/file/preUploadFile',
  fileUpload: fms_host + '/file/upload',
  //应付单
  getExpenseItemList: fms_host + '/expense/item/list', //费用项目
  getPayableList: fms_host + '/payable/order/list',
  getPayableDetail: fms_host + '/payable/order/detail',
  createPayableOrder: fms_host + '/payable/order/create', //创建应付单
  cancelPayableOrder: fms_host + '/payable/order/cancel', //作废应付单
  //预付单
  getDepositPaymentList: fms_host + '/deposit/payment/list',
  getDepositPaymentDetail: fms_host + '/deposit/payment/detail',
  createDepositPayment: fms_host + '/deposit/payment/createOrUpdate',
  cancelDepositPayment: fms_host + '/deposit/payment/cancel',
  //付款申请单
  getPaymentRequestList: fms_host + '/payment/request/list',
  getPaymentRequestDetail: fms_host + '/payment/request/detail',
  createPaymentRequest: fms_host + '/payment/request/createOrUpdate',
  cancelPaymentRequest: fms_host + '/payment/request/cancel',
  //付款单
  getPaymentNoteList: fms_host + '/payment/note/list',
  getPaymentNoteDetail: fms_host + '/payment/note/detail',
  createPaymentNote: fms_host + '/payment/note/createOrUpdate',
  cancelPaymentNote: fms_host + '/payment/note/cancel', //作废
  payPaymentNote: fms_host + '/payment/note/confirm/pay', //确认付款
  //发票
  getInvoiceList: fms_host + '/invoice/query/list',
  getInvoiceDetail: fms_host + '/invoice/detail',
  getMappingList: fms_host + '/invoice/get/map',
  createOrUpdateInvoice: fms_host + '/invoice/createOrUpdate',
  authInvoice: fms_host + '/invoice/auth', //发票认证
  cancelAuthInvoice: fms_host + '/invoice/cancel/auth', //发票取消认证
  entryInvoice: fms_host + '/invoice/entry', //发票入账
  cancelEntryInvoice: fms_host + '/invoice/cancel/entry', //发票取消入账
  bindInvoicePayable: fms_host + '/invoice/bind',
  payInvoice: fms_host + '/invoice/pay',
  rejectPayInvoice: fms_host + '/invoice/reject/paid',
  modifyInvoiceAmount: fms_host + '/invoice/modify/amount',
  checkInvoice: fms_host + '/invoice/check',
  //凭证
  getVoucherTemplateList: fms_host + '/voucher/template/list', //凭证模板列表
  generateVoucher: fms_host + '/voucher/generate', //生成凭证
  getVoucherList: fms_host + '/voucher/list', //凭证列表
  getVoucherDetail: fms_host + '/voucher/detail', //凭证详情
  crateOrUpdateVoucher: fms_host + '/voucher/crateOrUpdate', //手动创建凭证
  checkVoucher: fms_host + '/voucher/check', //凭证审核
  getAccountJournalDimensionList: fms_host + '/account/journal/dimension/list', //凭证分类账
  getAccountJournalList: fms_host + '/account/journal/list', //明细分类账列表
  getAccountJournalPeriodList: fms_host + '/account/journal/period/list', //科目总分类账列表
  getAccountDimensionList: fms_host + '/account/dimension/list', //核算维度列表
  //科目余额初始化
  getAccountJournalInitList: fms_host + '/account/journal/init/balance/list', //科目余额初始化列表
  getAccountJournalInitDetail: fms_host + '/account/journal/init/balance/detail', //科目余额初始化详情
  createOrUpdateAccountJournalInit: fms_host + '/account/journal/init/balance/createOrUpdate', //科目余额初始化创建或修改
  finishAccountJournalInit: fms_host + '/account/journal/init/balance/finish', //结束科目余额初始化
  getTrialBalanceList: fms_host + '/account/journal/trial/balance', //试算平衡表
  //基础信息
  getAccountElemenTabletist: fms_host + '/account/element/table/list', //会计要素列表
  getAccountElementist: fms_host + '/account/element/list', //会计要素列表
  getAccountList: fms_host + '/account/list', //会计科目列表
  getAccountDetail: fms_host + '/account/detail', //会计科目详情
  getAccountTableList: fms_host + '/account/table/list', //科目表列表
  createOrUpdateAccountTable: fms_host + '/account/table/createOrUpdate', //创建科目表
  getAccountSetList: fms_host + '/account/set/list', //账簿列表

  queryPurchaseSupplierList: fms_host + '/supplier/list', //供应商名称列表
  getCurrencyList: fms_host + '/baseInfo/currencyPage', //货币列表
  getcurrencyRateList: fms_host + '/baseInfo/currencyRatePage', //货币汇率列表
  getSettlementMethodList: fms_host + '/baseInfo/settlementMethod', //结算方式列表
  getBusinessUnitList: fms_host + '/business/unit/list', //结算组织列表
  queryAccountingCalendarDetailPage: fms_host + '/baseInfo/queryAccountingCalendarDetailPage', //会计期间列表

  //应收单
  getReceivableList: fms_host + '/receivable/order/list',
  getReceivableDetail: fms_host + '/receivable/order/detail',
  createReceivableOrder: fms_host + '/receivable/order/save', //创建应收单
  operateReceivableOrder: fms_host + '/receivable/order/operate',
  //收款单
  getReceiptNoteList: fms_host + '/receipt/note/list',
  getReceiptNoteDetail: fms_host + '/receipt/note/detail',
  createReceiptNote: fms_host + '/receipt/note/save',
  operateReceiptNote: fms_host + '/receipt/note/operate',
  //核销单
  getReceivableWrittenOffList: fms_host + '/receivable/writtenOff/list',
  createWrittenOff: fms_host + '/written/off/create',
  //审核接口
  checkOrder: fms_host + '/doc/check',
  findRelatedDoc: fms_host + '/doc/find', //查询关联单据
  getCommonList: fms_host + '/common/list',
  getCommonAction: fms_host + '/commonAction/list', //日志

  //资产基础信息
  getAssetTypeList: fms_host + '/assetBaseInfo/assetTypePage', //资产类别
  getInDecreaseMethodList: fms_host + '/assetBaseInfo/inDecreaseMethodPage', //资产增减方式
  getStoreLocationList: fms_host + '/assetBaseInfo/storeLocationPage', //资产存放地点
  saveStoreLocation: fms_host + '/assetBaseInfo/saveStoreLocation', //资产存放地点保存
  getAssetUseStatusList: fms_host + '/assetBaseInfo/assetUseStatusList', //资产使用状态
  getAssetUnitList: fms_host + '/assetBaseInfo/assetUnitList', //计量单位
  getAccountingPolicyPage: fms_host + '/assetBaseInfo/accountingPolicyPage', //会计政策
  getDepreciationMethodList: fms_host + '/assetBaseInfo/depreciationMethodList', //折旧方法
  getAssetBaseInfoMap: fms_host + '/assetBaseInfo/get/map',
  //资产卡片
  getAssetCardList: fms_host + '/assetCard/page',
  getAssetCardDetail: fms_host + '/assetCard/detail',
  saveAssetCard: fms_host + '/assetCard/save',
  operateAssetCard: fms_host + '/assetCard/operate',
  canChangeAssetCardList: fms_host + '/assetCard/toAddPage',
  //资产折旧单
  getAssetDepreciationList: fms_host + '/assetDepreciation/page',
  getAssetDepreciationDetail: fms_host + '/assetDepreciation/detail',
  getAssetDepreciationRecordList: fms_host + '/assetDepreciation/recordList',
  createAssetDepreciation: fms_host + '/assetDepreciation/generate',
  operateAssetDepreciation: fms_host + '/assetDepreciation/operate',
  getCurrentAccountingPeriod: fms_host + '/assetDepreciation/getCurrentAccountingPeriod',
  //资产变更单
  getAssetChangeList: fms_host + '/assetChange/page',
  getAssetChangeDetail: fms_host + '/assetChange/detail',
  createAssetChange: fms_host + '/assetChange/save',
  operateAssetChange: fms_host + '/assetChange/operate',
  assetChangeItemList: fms_host + '/assetChange/changeItemList',
  //资产减值单
  getAssetImpairmentList: fms_host + '/assetImpairment/page',
  getAssetImpairmentDetail: fms_host + '/assetImpairment/detail',
  getAssetImpairmentRecordList: fms_host + '/assetImpairment/recordList',
  createAssetImpairment: fms_host + '/assetImpairment/save',
  operateAssetImpairment: fms_host + '/assetImpairment/operate',
  //资产清理单
  getAssetCleanupList: fms_host + '/assetCleanup/page',
  getAssetCleanupDetail: fms_host + '/assetCleanup/detail',
  createAssetCleanup: fms_host + '/assetCleanup/save',
  operateAssetCleanup: fms_host + '/assetCleanup/operate',
  getAssetCleanupRecordList: fms_host + '/assetCleanup/recordList',

  //资金账户
  createOrUpdateCapitalAccount: fms_host + '/capital/createOrUpdate',
  getCapitalAccountList: fms_host + '/capital/list',
  getCapitalAccountDetail: fms_host + '/capital/detail',
  operateCapitalAccount: fms_host + '/capital/update/status',
  getCapitalJournalList: fms_host + '/capital/journal/list', //资金日记账

  //资金账户初始化
  getCapitalInitList: fms_host + '/capital/account/init/list', //资金账户初始化列表
  getCapitalInitDetail: fms_host + '/capital/account/init/detail', //资金账户初始化详情
  createOrUpdateCapitalInit: fms_host + '/capital/account/init/createOrUpdate', //资金账户初始化保存
  checkCapitalInit: fms_host + '/capital/account/init/check', //资金账户初始化审核

  getCapitalBalanceList: fms_host + '/capital/balance/list', //查询资金账户余额列表
  getCapitalItemList: fms_host + '/capital/item/list', //查询资金账户余额明细列表

  getCashFlowTabletist: fms_host + '/cash/flow/table/list', //现金流量表
  getCashFlowItemList: fms_host + '/cash/flow/item/list', //现金流量项目列表
  getCashFlowItemAccountMappingList: fms_host + '/cash/flow/item/account/mapping/list', //现金流量科目列表

  getFinancialStatementTemplateList: fms_host + '/financial/statement/template/query/list',
  getFinancialStatement: fms_host + '/financial/statement/query', //三大报表

  //合并报表
  getFinancialStatementSnapshot: fms_host + '/financial/statement/snapshot/query', //快照查询
  generateFinancialStatementSnapshot: fms_host + '/financial/statement/snapshot/generate', //快照生成
  mergeFinancialStatementSnapshot: fms_host + '/financial/statement/snapshot/merge', //快照合并
}
