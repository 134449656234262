export default {
  loading: false, //加载全局spin Loading
  lang: localStorage.getItem('lang') || 'zh',
  locale: getData('locale', {}),
  shopList: [], //店铺列表
  userInfo: getData('userInfo', {}),
  settlementBuList: getData('settlementBuList', []), //结算组织列表
  defaultBu: getData('defaultBu', {}), //默认结算组织
  userSession: getData('userSession', {}),
  currentShop: getData('currentShop', {}),
  currentApp: getData('currentApp', {}), //当前产品
  shopCode: '',
  nowPlatformList: [], //当前的平台列表，通过店铺来获取的。
  countryMap: {
    th: 1, //泰国
    TH: 1, //泰国
    Thailand: 1, //泰国
    VN: 2, //越南
    PH: 3, //菲律宾
    Philippines: 3, //菲律宾
    MY: 4, //马来西亚
    Malaysia: 4, //马来西亚
    CN: 6, //中国
    China: 6, //中国
  },
  exclude: null,
  panes: getData('panes', []),
  merchantList: getData('merchantList', []),
  shopPlatformList: getData('shopPlatformList', []),
  pagination_setting: {
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
  },
  dashboard_loading: false,
  menuList: getData('menuList', []),
  rate: 1,
  currencyList: getData('currencyList', []),
  currencyRateMapping: {}, //货币汇率
  account_purpose: [],
  asset_card_status: [],
  asset_operate_status: [],
  bank_capital_type: [],
  capital_type: [],
  cash_flow_type: [],
  contact_type: [],
  document_type: [],
  funding_direction: [],
  init_account_balance_status: [],
  init_capital_balance_status: [],
  invoice_status: [],
  invoice_type: [],
  invoice_biz_type: [],
  goods_type: [],
  expense_type: [],
  department_type: [],
  balance_direction: [],
  journal_voucher_status: [],
  journal_voucher_type: [],
  payable_order_biz_status: [],
  payable_order_invoice_status: [],
  payable_order_status: [],
  payable_order_type: [],
  payment_note_status: [],
  payment_note_type: [],
  payment_purpose: [],
  payment_request_status: [],
  payment_request_type: [],
  deposit_payment_status: [],
  deposit_payment_type: [],
  source_order_type: [],
  receipt_note_status: [],
  receipt_note_type: [],
  receivable_order_status: [],
  receivable_order_type: [],
  receipt_purpose: [],
  written_off_type: [],
  timeType: [],
}

function getData(name, defaultData) {
  let data = localStorage.getItem(name)
  if (data === undefined || data === 'undefined' || data === null || data === 'null') {
    return defaultData
  }
  try {
    return JSON.parse(data)
  } catch (err) {
    console.log(err)
  }
  return data
}
